$main-accent: #468ed0;

.bg-logo {
  background-image: url(../app/images/stones.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.image-ph {
  width: 100%;
}
.p-button-label {
  font-weight: 400;
}
.btn-main {
  background: $main-accent;
  border-radius: 0.5rem;
  padding: 0.1rem 2rem;
  border: none;
  color: white;
  line-height: 30px;
  display: flex;
  cursor: pointer;
  transition: 0.3s;
}

.btn-main:hover {
  filter: brightness(80%);
}

.player-wrapper {
  position: relative;
}

.react-player {
  position: relative;
  top: 0;
  left: 0;
}

.line {
  display: inline-block;
  height: 5px;
  border-top: 2px solid rgba(29, 30, 32, 0.1);

  &.blue {
    border-color: $main-accent;
  }
}

.form {
  label {
    font-size: 0.8rem;
    font-weight: 400;
    color: $main-accent;
    padding-left: 0.9rem;
  }
}

.label-container {
  position: relative;
  label {
    font-size: 0.8rem;
    font-weight: 400;
    color: $main-accent;
    position: absolute;
    top: -24px;
    left: 20px;
    z-index: 10;
  }
}

.intro-bg {
  background-image: url(../app/images/register-step-bg-mobile.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
}
.welcome-bg {
  background-image: url(../app/images/register-step-bg-mobile.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
}
.register-bg {
  background-image: url(../app/images/register-step-bg-mobile.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
}

.register-steps {
  .content-wrap {
    // background-image: url(../app/images/register-step-bg-mobile.png);
    background-size: cover;
  }

  & .checkbox-unselected {
    width: 24px;
    height: 24px;
    border-radius: 8px;
    background: transparent;
    border: 1px solid rgba(142, 143, 155, 1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-right: 15px;
  }

  & .register-checkbox-wrap label {
    background-color: rgba(232, 220, 159, 1);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    border-left: 3px solid transparent;

    &.selected {
      border-left: 3px solid rgba(70, 142, 208, 1);
    }
  }

  & .checkbox-selected {
    width: 24px;
    height: 24px;
    border-radius: 8px;
    background: rgba(70, 142, 208, 1);
    border: 1px solid rgba(70, 142, 208, 1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    gap: 10px;
  }

  & .checkbox-selected .pi-check {
    font-size: 16px;
    color: white;
    position: absolute;
  }

  & .label-text {
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(29, 30, 32, 1);
  }
}
.previous-next-btn-wrap {
  & .p-button.p-button-text {
    color: rgba(70, 142, 208, 1);
  }

  & .previous-btn {
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  & .next-btn {
    background-color: rgba(70, 142, 208, 1);
    font-family: 'Work Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    padding-left: 45px;
    padding-right: 45px;
  }
}

.checkbox-bg .form-input-field {
  background-color: rgba(232, 220, 159, 1);
}
.yellow-bg-fields .p-inputtext {
  background-color: rgba(232, 220, 159, 1);
}
.left-column {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* Ensure that the right column is scrollable */
.right-column {
  min-height: 100vh;
  height: 200vh;
  /* Adjust this based on your needs */
  overflow-y: scroll;
}
.download-pdf-button {
  background-color: $blue1;
  &:enabled:hover {
    border: 1px solid $blue1;
    background-color: $blue1;
  }
  .p-button-label {
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
  }
  span {
    color: white;
  }
}
.back-to-home-btn.p-button-outlined {
  border: 2px solid $blue1;
  color: $blue1;
  &:enabled:hover {
    border: 2px solid $blue1;
  }
  span {
    color: $blue1;
  }
  .p-button-label {
    color: $header-color;
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
  }
}

.final-result {
  .category-test-wrap {
    h2 {
      font-family: Urbanist;
      font-size: 36px;
      font-weight: 700;
      line-height: 54px;
      letter-spacing: 0em;
      color: $header-color;
      margin: 0 0 10px 0;
    }
    p {
      font-family: Work Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      color: $grey3;
      margin: 0;
    }
  }
  .results-text {
    & .text-wrap {
      h6 {
        margin: 0 0 10px 0;
        font-family: Urbanist;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        color: $header-color;
      }
      p {
        font-family: Work Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: $grey3;
        margin: 0;
      }
    }
  }
  & footer {
    & h6,
    & a {
      font-family: Urbanist;
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
      letter-spacing: 0em;
      color: $header-color;
    }
    & a {
      color: $link-color;
    }
    & h4 {
      font-family: Urbanist;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0em;
      color: $header-color;
    }
    & p {
      font-family: Work Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      color: $grey3;
    }
  }
}
.goals-wrap {
  .goals-list {
    border-top: 1px solid #b3b9c3;
    .goal-item {
      background-color: white;
      color: black;
      border-radius: 20px;
      border-color: rgba(179, 185, 195, 1);
      color: rgba(29, 30, 32, 1);
      &:focus {
        outline: none;
        box-shadow: none;
      }

      span {
        margin-right: 5px;
        color: rgba(179, 185, 195, 1);
      }
    }
  }

  .grade-btn {
    font-family: Urbanist;
    color: white;
    border-radius: 20px;
    line-height: 18px;
    z-index: 2;
    position: relative;
    height: 47px;
    button {
      box-shadow: none;
      span {
        color: white;
      }
    }
  }
  .bg-line-mobile {
    position: absolute;
    z-index: 1;
    left: calc(50% - 70px);
    top: calc(50% - 81px);
  }
  .bg-line-desktop {
    position: absolute;
    z-index: 1;
    left: calc(50% - 150px);
    top: 25px;
    width: 300px;
  }
}

.p-progress-spinner-circle {
  stroke: $blue1 !important;
}

.p-paginator-element {
  border-radius: 8px !important;
  width: 40px !important;
  height: 40px !important;
  min-width: 0 !important;
  @media (max-width: 440px) {
    width: 33px !important;
  }
  @media (max-width: 380px) {
    width: 27px !important;
  }
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  border: 1px solid $blue1 !important;
  color: $blue1 !important;
  background: none !important;
}

.MuiModal-root {
  z-index: 1000 !important;
}

.pebbles-2 {
  background-position: center center;
  background-clip: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-origin: padding-box;
}

code {
  display: block;
  text-wrap: wrap;
  width: 100%;
}
