:root {
  --primary-color: #468ed0;
}
$blue1: #468ed0;
$blue2: #468;
$blue3: #06f;
$blue4: #cddff0;
$blue5: #377bb8;

$border-grey: #b3b9c3;
$grey: #edf0f6;
$grey1: #797f8d;
$grey2: #edf0f6;
$grey3: #43474f;
$grey4: #b3b9c3;
$grey5: #8e8f9b;
$grey6: #f1f4f9;

$border-grey: $grey4;

$red1: #f3376f;
$red2: #ff9fab;
$red3: #fcebed;
$red4: #d92e4b;

$yellow1: #e8dc9f;
$yellow2: #fcf1b6;
$yellow3: #fff5d8;
$yellow4: #ffbf00;

$green1: #7da782;
$green2: #f2f9f9;
$green3: #d3ecd6;
$green4: #e6f5e7;
$green5: #00950f;
$green6: #71a97e;

$bg-main: $yellow2;
$link-color: $blue1;
$link-color-hover: $blue2;
$font-color-main: $grey3;
$header-color: #1d1e20;
